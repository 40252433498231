
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';



import { environment } from 'environments/environment';

@Injectable()
export class ApiService {
  constructor(
    private httpClient: HttpClient,
    private router: Router,
  ) {}

  private formatErrors(error: any) {
    let errorMsg = error.error;
    if (error.status === 0 || error.status === 500) {
      errorMsg = error.statusText;
    }

    return observableThrowError(errorMsg);
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.httpClient.get(`${environment.apiUrl}${path}`, {params : params}).pipe(
    catchError(this.formatErrors));
  }

  put(path: string, body: Object = {}): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.put(
      `${environment.apiUrl}${path}`,
      JSON.stringify(body), { headers: headers}
    ).pipe(
    catchError(this.formatErrors));
  }

  patch(path: string, body: Object = {}): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.patch(
      `${environment.apiUrl}${path}`,
      JSON.stringify(body), { headers: headers}
    ).pipe(
    catchError(this.formatErrors));
  }

  post(path: string, body: Object = {}): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(
      `${environment.apiUrl}${path}`,
      JSON.stringify(body), { headers: headers}
    ).pipe(
    catchError(this.formatErrors));
  }

  delete(path: string): Observable<any> {
    return this.httpClient.delete(`${environment.apiUrl}${path}`).pipe(
    catchError(this.formatErrors));
  }

  authPost(path: string, creds: Object = {}): Observable<any> {
    const token = btoa(environment.clientId + ':' + environment.clientSecret);
    let headers = new HttpHeaders().set('Authorization', 'Basic ' + token);
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    return this.httpClient.post(
      `${environment.authUrl}${path}`, creds, { headers: headers}
    ).pipe(
    catchError(this.formatErrors));
  }

  putFile(path: string, body: Object = {}): Observable<any> {
    return this.httpClient.put(
      `${environment.apiUrl}${path}`,
      body
    ).pipe(
    catchError(this.formatErrors));
  }

  patchFile(path: string, body: Object = {}): Observable<any> {
    return this.httpClient.patch(
      `${environment.apiUrl}${path}`,
      body
    ).pipe(
    catchError(this.formatErrors));
  }

  postFile(path: string, body: Object = {}): Observable<any> {
    return this.httpClient.post(
      `${environment.apiUrl}${path}`,
      body
    ).pipe(
    catchError(this.formatErrors));
  }

  getFile(path: string): Observable<Blob> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.httpClient
      .get(`${environment.apiUrl}${path}`, {
        headers: headers,
        responseType: 'blob'
      });
  }

  getExcelFile(path: string, params: HttpParams = new HttpParams()): Observable<Blob> {
    return this.httpClient
      .get(`${environment.apiUrl}${path}`, {
        responseType: 'blob',
        params : params
      });
  }

  getFileParams(path: string, params: HttpParams = new HttpParams()): Observable<Blob> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.httpClient
      .get(`${environment.apiUrl}${path}`, {
        headers: headers,
        responseType: 'blob',
        params : params
      });
  }

  getExternal(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.httpClient.get(`${path}`, {params : params}).pipe(
    catchError(this.formatErrors));
  }
}
