import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';

import { User, AuthService, StorageService, ToasterService } from '@app/shared/index';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  currentUser: User;
  mainNavigation: any = [];
  userEmail: string;
  userAcronym: string;

  loadingMainNav: boolean;

  start: Number = 1;
  params: HttpParams;
  loadingNotifications: boolean;
  notifications = {
    count: 0,
    results: [],
    unviewed_count: 0,
    next: '',
    previous: '',
  };
  notificationList: Array<any> = [];

  updateNotification: boolean;
  notificationCount: 0;

  constructor(
    private router: Router,
    private authService: AuthService,
    private storage: StorageService,
  ) {}

  ngOnInit() {
    this.authService.currentUser.subscribe(
      (userData) => {
        this.currentUser = userData;
        this.userEmail = userData['email'];
        const matches = userData['name'].match(/\b(\w)/g);
        this.userAcronym = matches.join('');
      }
    );

    this.loadingMainNav = true;
    if (this.storage.retrieve('navigation', 'session')) {
      this.loadingMainNav = false;
      this.mainNavigation = this.storage.retrieve('navigation', 'session');
    } else {
      this.storage.observe('leftNavigation', 'session')
      .subscribe((value) => {
        setTimeout(() => {
          this.loadingMainNav = false;
          this.mainNavigation = this.storage.retrieve('navigation', 'session');
        });
      }, error => {
        this.loadingMainNav = false;
      });
    }

    this.notificationList = [];
    // this.getNotifications();
  }


  logout() {
    this.authService.purgeAuth();
    this.router.navigate(['/', 'login']);
  }

  onRouteClick(item) {
    let redirectRoute = item['slug'];
    if (item['children']['left'] && item['children']['left'][0]) {
      const leftMainRoute = item['children']['left'][0];
      redirectRoute += '/' + leftMainRoute['slug'];
      if (leftMainRoute['children'] && leftMainRoute['children']['left'] && leftMainRoute['children']['left'][0]) {
        redirectRoute += '/' + leftMainRoute['children']['left'][0]['slug'];
      }
    }
    this.router.navigateByUrl(redirectRoute);
  }


  routeToDefaultPath() {
    if (this.storage.retrieve('defaultRoute', 'session')) {
      const defaultRoute = this.storage.retrieve('defaultRoute', 'session');
      this.router.navigate([defaultRoute]);
    } else {
      this.router.navigate(['/', 'dashboard']);
    }
  }

}
