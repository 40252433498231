import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'unitFormat'
})
export class UnitFormat implements PipeTransform {
  transform(unit: any, value: any): any {
    if (['LB', 'lb', 'lbs'].includes(unit)) {
      if (+value === 1) {
        return 'lb';
      } else {
        return 'lbs';
      }
    } else {
      return 'oz';
    }
  }
}
