import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';

import {throwError as observableThrowError,  Observable } from 'rxjs';
import {catchError} from 'rxjs/operators';

import { AuthService, ToasterService } from '@app/shared/services';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private inj: Injector,
    private router: Router
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const authService = this.inj.get(AuthService);
    const jwtToken = authService.getToken();
    const token = this.authService.getToken();

    if (token && !request.headers.has('Authorization')) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }

    return next.handle(request).pipe(catchError((error) => {
      if (error instanceof HttpErrorResponse) {
        switch ((<HttpErrorResponse>error).status) {
          case 400:
            return this.handle400Error(error);
          case 401:
            authService.collectFailedRequest(request);
            return this.handle401Error(error);
          case 403:
            return this.handle403Error(error);
          case 404:
            return this.handle404Error(error, request.url);
          default:
            return observableThrowError(error);
        }
      } else {
        return observableThrowError(error);
      }
    }));

  }

  handle400Error(error) {
    if (error && error.status === 400 && error.error && error.error.error === 'invalid_grant') {
      // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
      return this.logoutUser(error);
    }

    return observableThrowError(error);
  }

  handle403Error(error) {
    if (error && error.status === 403 && error.error && error.error.detail) {
      const errorMsg = error.error.detail;
      if (errorMsg === 'Signature has expired.') {
        return this.logoutUser();
      } else {
        const toastr = this.inj.get(ToasterService);
        toastr.error(errorMsg);
      }
    }

    return observableThrowError(error);
  }

  handle401Error(error) {
    if (error && error.status === 401 && error.error && error.error.detail) {
      const errorMsg = error.error.detail;
      if (errorMsg === 'Authentication credentials were not provided.') {
        return this.logoutUser();
      } else {
        const toastr = this.inj.get(ToasterService);
        toastr.error(errorMsg);
      }
    }

    return observableThrowError(error);
  }

  handle404Error(error, url) {
    const toastr = this.inj.get(ToasterService);
    // toastr.error('404 not found ' + url);

    return observableThrowError('');
  }

  logoutUser(error = '') {
    const authService = this.inj.get(AuthService); // authservice is an angular service

    // Remove sessions and route to the login page
    authService.purgeAuth();
    this.router.navigate(['/login']);
    return observableThrowError(error);
  }

}
