import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ToasterService {

  timeOut = 3000;

  constructor(
    private toastr: ToastrService
  ) {
  }

  success(message: string, title: string = 'Success!') {
    this.toastr.success(message, title, {
      timeOut: this.timeOut
    });
  }

  info(message: string, title?, config?) {
    if (!config) {
      config = {
        timeOut: this.timeOut
      }
    }
    this.toastr.info(message, title, config);
  }

  warning(message: string) {
    this.toastr.warning(message, '', {
      timeOut: this.timeOut
    });
  }

  error(message: string, title: string = 'Error!') {
    this.toastr.error(message, title, {
      timeOut: this.timeOut
    });
  }

}
