import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ConfirmationDialogComponent } from './confirmation-dialog.component';

@Injectable()
export class ConfirmationDialogService {

  constructor(private modalService: NgbModal) { }

  public confirm(
    title: string,
    message: string,
    btnOkText: string = 'OK',
    color: string = '#EB4D4B',
    btnCancelText: string = 'Cancel',
    dialogSize: 'sm'|'lg' = 'lg'
  ): Promise<boolean> {
    const options = {
      backdrop: false
    };
    document.querySelector('body').classList.toggle('show-confirmation-overlay');
    const modalRef = this.modalService.open(ConfirmationDialogComponent, options);
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;
    modalRef.componentInstance.color = color;
    modalRef.result.then((result) => {
      document.querySelector('body').classList.remove('show-confirmation-overlay');
    }, (reason) => {
      document.querySelector('body').classList.remove('show-confirmation-overlay');
    });

    return modalRef.result;
  }

}
