import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: 'input[trimSpecialChar]'
})

export class TrimSpecialChar {

  constructor(
    private _el: ElementRef,
    private control: NgControl) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    const value = String(this._el.nativeElement.value || '').replace(/[^A-Za-z0-9\.\-\s\,]*/g, '');
    this.control.control.setValue(value);
  }

}
