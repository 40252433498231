import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ContentLayoutComponent } from './layout';
import { LoginComponent } from './login/login.component';

import { NoAuthGuard, AuthGuard } from '@app/core';

export const AppRoutes: Routes = [
  {
    path: '', redirectTo: 'shipment', pathMatch: 'full',
  },
  {
    path: '',
    component: ContentLayoutComponent,
    canActivate: [AuthGuard], // Should be replaced with actual auth guard
    children: [
      {
        path: 'settings',
        loadChildren: 'app/settings/settings.module#SettingsModule'
      },
      {
        path: 'shipment',
        loadChildren: 'app/shipment/shipment.module#ShipmentModule'
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent
  }
];



