import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'currencyFormat'
})
export class CurrencyFormat implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value < 0) {
      const newVal = Math.abs(value);
      const tmp = newVal.toString().split('.');
      if (tmp.length === 1) {
        return '($' + Math.abs(value) + '.00)';
      } else {
        return '($' + Math.abs(value) + ')';
      }
    } else if (Number(value) === 0) {
      return '$0.00';
    } else {
      const deciDigits = String(value).split('.');
      if (deciDigits.length > 1) {
        if (deciDigits[1].length === 0) {
          return '$' + value + '00';
        } else if (deciDigits[1].length === 1) {
          return '$' + value + '0';
        } else {
          if (deciDigits[1] === '00') {
            return '$' + value;
          }
          return '$' + Math.round(value * 100) / 100;
        }
      } else {
        return '$' + value + '.00';
      }
    }
  }
}
