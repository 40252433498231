import { Component, ViewContainerRef, OnInit } from '@angular/core';

import { AuthService } from '@app/shared/index';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {

  constructor (
    public vcr: ViewContainerRef,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.authService.populate();
  }
}
